.center {
    padding-left: 10px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        .title {
            font-size: 10px;
            font-weight: bold;
            color: #999;
        }
    }
}

.sidebar {
    flex: 1;
    min-height: 100vh;
    background-color: white;
    // border-right: 0.5px solid rgb(230, 227, 227);
    .top {
        background-color: #12232E;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border-right: red;
        .logo {
            font-size: 20px;
            font-weight: bold;
            color: #D2042D;
            .img {
                height: 50%;
                width: 90%;
            }
        }
    }

    .bottomHalf {
        height: 100%;
        border-right: 0.5px solid rgb(230, 227, 227);
        .center {
            padding-left: 10px;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                .title {
                    font-size: 10px;
                    font-weight: bold;
                    color: #999;
                    margin-top: 15px;
                    margin-bottom: 5px;
                }
                li {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    cursor: pointer;
                    &:hover {
                        background-color: #ece8ff;
                    }
                    .icon {
                        font-size: 18px;
                        color: #000000;
                    }
                    span {
                        font-size: 13px;
                        font-weight: 600;
                        color: #888;
                        margin-left: 10px;
                    }
                }
            }
        }

    }


    hr {

        height: 0;
        border: 0.5px solid rgb(230, 227, 227);
    }
}