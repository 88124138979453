@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Nunito', sans-serif;
}

main {
  padding: 1rem;
  min-height: 100vh;

}

article {
  font-size: 1.2rem;
  width: 90%;
  margin: 1rem;
}

// button {
//   margin: 1rem 1rem 0.5rem;
//   font: inherit;
//   font-size: 2rem;
//   padding: 0.5em 1.5em;
//   cursor: pointer;
//   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//   background-color: #2caed6;

// }

.column {
  // background: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10%;
  background: hsla(197, 14%, 57%, 1);

  background: linear-gradient(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%);

  background: -moz-linear-gradient(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%);

  background: -webkit-linear-gradient(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%);

  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0061FF", endColorstr="#60EFFF", GradientType=1);


  .container {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 35%;
    height: 500px;
    background: #000428;
  }

  @media screen and (max-width: 960px) {
    .container {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 500px;
      background: #000428;
    }
  }

  .landinglogo {
    display: flex;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 15%;
    width: 80%;
    height: auto;
  }

  .text {
    font-family: "Lucida Console", Monaco, monospace;
    // font-weight: bold;
    color: #DCDCDC;
    border-radius: 20%;
    padding-bottom: 10%;
  }

  .button {
    padding-bottom: 10%;
  }
}