.graphs {
    // flex: 2;
    
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 20px;
    padding: 10px;
    height: 470px;

    .top {
        // background-color: #12232E;

        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .bottom {
        // height: 100%;
        // border-right: 0.5px solid rgb(230, 227, 227);
        display: flex;
        // align-items: center;

    }
}