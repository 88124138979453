.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: 200px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: gray;

        .title {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .bottom {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .batteryChart {
            width: 100px;
            height: 100px;
            
        }

        .title {
            font-weight: 500;
            color: gray;
        }
    }
}