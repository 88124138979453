.navbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  background-color: #12232E;
  border-color: transparent;

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: absolute;
        right: 2%;
        .welcome{
          color: white;
          font-size: small;
          padding-right: 20px;
        }
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50px;
        }
      }
    }
  }
}